export const headline = {
    title: "Empower Your People. Ignite Your Success.",
    copy: [
        "At Blue House Solutions, we specialize in empowering small businesses, like yours, to harness the full potential of their workforce through strategic operational transformations.",
        "We understand that as your business expands, you face unique challenges. We're dedicated to guiding you through the complexities of operational strategy and process improvement. We collaborate closely with you to create customized systems and processes for the individuals driving your business forward, ensuring that work works again.",
        "We'll help you navigate the complexities of growth, streamline your operations, and cultivate an environment where your people can thrive."
    ],
    list: [
        "Is your business experiencing rapid growth?",
        "Are your processes struggling to keep up?",
        "Are you keenly aware that your people are the driving force behind your success?"
    ]
};

export const mission = {
    title: "Our Mission",
    subHeading: "Making Work Better For Your Team",
    copy: [
        "Our philosophy is simple. ",
        "Every business runs on people. People should love their work. They thrive when their work drives big progress. And when your people thrive, your business thrives. We help you make their work flow better.",
        "Because if it doesn't work for the user, it doesn't work.",
       
    ]
};

export const about = {
    title: "About Us",
    subHeading: "",
    copy: [
        "We're not your typical consulting firm. We're not here to keep you dependent on us forever. We believe in empowering you and your people to create a system that works for you. And to be able to run it completely yourselves. Our goal is to equip you with the knowledge and tools to thrive independently. We're here to help as long as you need us...and not a minute more.",

        "We're passionate about helping small businesses like yours succeed. Our solutions are designed to leverage your team's strengths and talents. By focusing on people-centric processes, we unleash the true potential of your organization. Our team takes the time to understand your unique culture, challenges, and apsirations.",

        "At Blue House Solutions, we don't believe in \"one size fits all\" approaches. We tailor our strategies to suit your specific needs, allowing your business to flourish while maintaining its identity and values. Together, we'll build a roadmap to success, empowering you to achieve greatness without being shackled to us.",

        "We bring deep experience in process optimization, operational strategy, organizational development. Our goal is always to empower you with the knowledge and tools you need to thrive independently. After all, you've already accomplished so much. You deserve a partner who cares about your long-term growth and sustainability. Your people and your success are at the heart of everything we do."

    ]
};

export const different = {
    title: "What Makes Us Different",
    sectionOne: {
        subHeading: "Built For You",
        copy: [
            "We get to know your business and build solutions that work for you and your team. We never try to fit your team into a \"one size fits all\" approach. Our solutions are designed and executed based on the real operating environment in which you work. And they're designed so that you can run them without us. We help you design and build a system that you are completely in control of.",
        ]
    },
    sectionTwo: {
        subHeading: "Designed For Your Team",
        copy: [
            "We are change management experts. Our transparent and strategic communication wins your team’s trust early and leverages their expertise. Our process focuses on early wins to fuel momentum and buy-in. The systems we engineer stick because they make sense to your team.",
        ]
    },
    sectionThree: {
        subHeading: "Tangible Results",
        copy: [
            "With Blue House Solutions, you see measurable results quickly.",
        ],
        list: [
            "Move faster",
            "Improve work flows",
            "Strengthen your team",
            "Make things easier for yourself",
        ],
        copy2: [
            "Business never stops. Your processes have to keep up. We'll help you make lasting changes that serve your business well.",
        ],
    },
}; 

export const process = {
    title: "Our Process",
    copy: [],
    list: [
        {
            emphasis: "Work Flow Analysis.",
            copy: "Working with your key team members, we'll analyze your work flow. We'll find what is working and what needs realigning.",
        },
        {
            emphasis: "Drive Immediate Progress.",
            copy: "Using the requirements we elicited from your team's real work, we'll implement small changes that produce immediate progress. We'll get your team engaged and excited about improving your business processes.",
        },
        {
            emphasis: "System Road Mapping.",
            copy: "We'll provide recommendations for future enhancements that your team can implement themselves. By putting them in charge of future executions, we can save you money and promote lasting change that benefits your business in the short and long term.",
        }
    ]
};

export const contact = {
    title: "Contact Us",
    copy: [
        "Connect with us",
    ],
    email: "Hello@bluehousesolutions.org",
    linkedIn: {
        copy: "LinkedIn",
        url: "https://www.linkedin.com/company/bluehousesolutions"
    }
};

export const whoIs = {
    name: "Learn More",
    description: "Connect with us. We look forward to serving your team.",
}

export const copyright = {
    copy: [
        `© 2022-${new Date().getFullYear()} by Blue House Solutions`,
        "All rights reserved."
    ]
}