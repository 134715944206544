// Layout
import { Box } from "../layout/Box"

// Content
import { mission } from "../constants/content";

// Scaling
import { copySize, headingSize, scale, subHeadingSize } from "../constants/cssScale";

// Colors
import { colors } from "../constants/colors";
import { SideBar } from "../layout/SideBar";
import { Stack } from "../layout/Stack";
import { BasicCard } from "./subComponents/BasicCard";

export const OurMission = () => {
    return (
        <Box id="mission" style={{ backgroundColor: colors.secondary, borderRadius: scale.s1, }}>
            <SideBar>
                {/* HEADING */}
                <Box style={{
                    fontSize: headingSize, 
                    color: colors.primary, 
                    backgroundColor: "revert",
                    width: "20%",
                    color: colors.primaryTrim,
                }} >
                        { mission.title }
                </Box>

                <BasicCard>
                    <Stack>
                        {/* SUBHEADING */}
                        <div style={{fontSize: subHeadingSize, color: colors.accent}} >{ mission.subHeading }</div>

                        {/* COPY */}
                        <Stack>
                            { 
                                mission.copy.map(item => {
                                    return (
                                        <div style={{fontSize: copySize, color: colors.primary}} >{item}</div>
                                    )
                                }) 
                            }
                        </Stack>
                    </Stack>
                </BasicCard>
            </SideBar>
        </Box>
    )
}