import { colors } from "../constants/colors"
import { scale } from "../constants/cssScale"
import { Box } from "../layout/Box"
import { Cluster } from "../layout/Cluster"
import { Stack } from "../layout/Stack"
import { WhoIAm } from "./subComponents/WhoIAm"

import logoStampV2 from '../assets/logoStampV2.png'
import { contact, copyright } from "../constants/content"

export const Footer = () => {
    return (
        <Box style={{backgroundColor: "revert", padding: scale.s3, height: "20rem"}}>
            <Stack>

            
                <Cluster style={{alignItems: "flex-start", justifyContent: "space-between"}}>

                    {/* CONTACT */}
                    <Box style={{ backgroundColor: "revert" }}>
                        <Cluster style={{alignItems: "flex-start"}} >
                            <WhoIAm />
                            
                            <Box style={{flexGrow: "2", backgroundColor: "revert"}}>
                                <Box style={{ backgroundColor: "revert", padding: scale.g1 }}>
                                    <Stack>
                                        <div style={{fontSize: scale.s1, color: colors.accent}}>Contact</div>
                                        <div>{contact.email}</div>
                                        
                                        <div>
                                            <a href={contact.linkedIn.url} target="blank">{contact.linkedIn.copy}</a>
                                        </div>

                                    </Stack>
                                </Box>            
                            </Box>
                        </Cluster>
                    </Box>

                    {/* COPYRIGHT */}
                    <Box style={{ backgroundColor: "revert", padding: scale.s0}}>
                        <Stack>
                            
                            <img src={logoStampV2} />
                        </Stack>
                        
                    </Box>
                </Cluster>
                
                <Box>
                    {
                        copyright.copy.map((item) => {
                            return (
                                <div>{item}</div>
                            )
                        })
                    }
                </Box>
            </Stack>

            
            

            
        </Box>
    )
}