import { Box } from "../../layout/Box"
// import bannerLogo from "./../../assets/logBannerV2.png"
// import stampLogo from "./../../assets/logoStampV2.png"

import "./header.css";

export const Header = () => {
    return (
        <Box classNames="bhs_header__logo " style={{backgroundColor: 'revert'}}>
            {/* <img src={stampLogo} srcSet={`${stampLogo} 391w, ${bannerLogo} 500w`} style={{ maxWidth: "100%", maxHeight: "100%" }} /> */}
        </Box>
    )
}