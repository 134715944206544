// Layout
import { Box } from "../layout/Box"
import { SideBar } from "../layout/SideBar";
import { Stack } from "../layout/Stack";

// Content
import { about } from "../constants/content";

// Scaling
import { copySize, headingSize, scale, subHeadingSize } from "../constants/cssScale";

// Colors
import { colors } from "../constants/colors";

import aboutUsImg from "./../assets/saguaro.jpg"
import { BasicCard } from "./subComponents/BasicCard";


export const AboutUs = () => {
    return (
        <Box style={{ backgroundColor: "revert", padding: 'revert' }}>
            <SideBar>
                {/* COPY */}
                <BasicCard style={{ width: "40%", border: `1px solid ${colors.primaryTrim}`}}>
                    <Stack>
                        {/* HEADING */}
                        <div style={{fontSize: headingSize, color: colors.primary}} >{ about.title }</div>

                        {/* SUBHEADING */}
                        <div style={{fontSize: subHeadingSize, color: colors.accent}} >{ about.subHeading }</div>

                        {/* COPY */}
                        <Stack>
                            { 
                                about.copy.map(item => {
                                    return (
                                        <div style={{fontSize: copySize, color: colors.primary}} >{item}</div>
                                    )
                                }) 
                            }
                        </Stack>
                    </Stack>
                </BasicCard>

                <Box style={{ backgroundColor: "revert"}}>
                    <img src={aboutUsImg} width="100%" />
                </Box>
            </SideBar>
        </Box>
    )
}