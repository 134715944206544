import logo from './logo.svg';
import './App.css';
import { Headline } from './components/Headline';
import { Box } from './layout/Box';
import { colors } from './constants/colors';
import { MakesUsDifferent } from './components/makesUsDifferent';
import { OurMission } from './components/Mission';
import { AboutUs } from './components/AboutUs';
import { scale } from './constants/cssScale';
import { Stack } from './layout/Stack';
import { Footer } from './components/Footer';
import { Header } from './components/header/Header';

function App() {
  return (
      <Box id="app" style={{ backgroundColor: colors.secondaryTrim}}>
        <Stack>
            {/* HEADER */}
            <Header />

            <Headline />
            <Box style={{ backgroundColor: "revert"}}>
              <Stack>
                <OurMission />
                <AboutUs />
              </Stack>
              
            </Box>
            
            <MakesUsDifferent />

            <Box style={{backgroundColor: 'revert'}}>
              <hr style={{ borderTop: `1px solid ${colors.primaryTrim}` }} />
            </Box>

            <Footer />

            
          </Stack>
      </Box>
  );
}

export default App;
