// Layout
import { Box } from "../layout/Box"
import { SideBar } from "../layout/SideBar";
import { Stack } from "../layout/Stack";
import { Cluster } from "../layout/Cluster";

// Scaling
import { copySize, headingSize, scale, subHeadingSize } from "../constants/cssScale";

// Colors
import { colors } from "../constants/colors";

// Content
import { different } from "../constants/content";

// Sub Components
import { InvisibleCard } from "./subComponents/InvisibleCard";
import { BasicCard } from "./subComponents/BasicCard";

import tangibleImg from "./../assets/hands.jpg"


export const MakesUsDifferent = () => {
    return (
        <Box id="MakesUsDifferent" style={{margin: scale.s1, backgroundColor: colors.secondaryTrim, padding: "revert"}}>
           
           {/* HEADING */}
           <Box style={{
            fontSize: headingSize, 
            color: colors.primary, 
            backgroundColor: colors.secondaryTrim
            }} >
                { different.title }
            </Box>

            <SideBar>
                {/* INVISIBLE CARDS (COL 1) */}
                <Box style={{ width: "40rem", backgroundColor: colors.secondaryTrim }}>
                    
                    <Stack>
                        {/* BUILD FOR YOU CARD */}
                        <InvisibleCard id="Built For You">
                            <Stack>
                                {/* SUBHEADING */}
                                <div style={{fontSize: subHeadingSize, color: colors.accent}} >{ different.sectionOne.subHeading }</div>
                                
                                {/* COPY */}
                                { 
                                    different.sectionOne.copy.map(item => {
                                        return (
                                            <div style={{fontSize: copySize, color: colors.primary}} >{item}</div>
                                        )
                                    }) 
                                }
                            </Stack>
                        </InvisibleCard>

                        {/* DESIGNED FOR YOUR TEAM CARD */}
                        <InvisibleCard id="Designed for your team">
                        <Stack>
                                {/* SUBHEADING */}
                                <div style={{fontSize: subHeadingSize, color: colors.accent}} >{ different.sectionTwo.subHeading }</div>
                                
                                {/* COPY */}
                                { 
                                    different.sectionTwo.copy.map(item => {
                                        return (
                                            <div style={{fontSize: copySize, color: colors.primary}} >{item}</div>
                                        )
                                    }) 
                                }
                            </Stack>
                        </InvisibleCard>
                    </Stack>
                </Box>

                
                {/* TANGIBLE RESULTS (COL 2) */}
                <BasicCard>
                    <Stack>
                        {/* SUBHEADING */}
                        <div style={{fontSize: subHeadingSize, color: colors.secondary}} >{ different.sectionThree.subHeading }</div>
                        
                        {/* COPY */}
                        { 
                            different.sectionThree.copy.map(item => {
                                return (
                                    <div style={{fontSize: copySize, color: colors.primary}} >{item}</div>
                                )
                            }) 
                        }

                        {/* List */}
                        <ul>
                            { 
                                different.sectionThree.list.map(item => {
                                    return (
                                        <li style={{fontSize: copySize, color: colors.primary}} >{item}</li>
                                    )
                                }) 
                            }
                        </ul>

                        {/* COPY */}
                        { 
                            different.sectionThree.copy2.map(item => {
                                return (
                                    <div style={{fontSize: copySize, color: colors.primary}} >{item}</div>
                                )
                            }) 
                        }
                        <Cluster style={{ justifyContent: "flex-end" }}>
                            {/* <Box style={{backgroundColor: colors.accent, width: "100px", height: "100px"}}> */}
                                <img src={tangibleImg} />
                            {/* </Box> */}
                        </Cluster>
                    </Stack>
                </BasicCard>
            
            </SideBar>
            
        </Box>
    )
}