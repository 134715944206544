
import { color } from "style-value-types"
import { colors } from "../../constants/colors"
import { scale } from "../../constants/cssScale"
import { Box } from "../../layout/Box"

export const BasicCard = ({children, style}) => {
    console.log({style})

    const mergedStyles = {
        ...style,
        margin: scale.s1, 
        borderColor: colors.primaryTrim,  
        border: `1px solid ${colors.primaryTrim}`, 
        borderRadius: scale.s1,
        backgroundColor: colors.base,
    }

    console.log({mergedStyles})
    return (
        <Box id="foobar" style={ mergedStyles }>
            {children}
        </Box>
    )
}