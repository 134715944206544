export const scale = {
    g5 : "var(--s-5)",
    g4 : "var(--s-4)",
    g3 : "var(--s-3)",
    g2 : "var(--s-2)",
    g1 : "var(--s-1)",
    g0 : "var(--s-0)",
    s1 : "var(--s1)",
    s2 : "var(--s2)",
    s3 : "var(--s3)",
    s4 : "var(--s4)",
    s5 : "var(--s5)",
}

export const headingSize = scale.s3;

export const subHeadingSize = scale.s2;

export const copySize = scale.s1;