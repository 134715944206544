import { whoIs } from "../../constants/content"
import { scale } from "../../constants/cssScale"
import { Box } from "../../layout/Box"
import { Stack } from "../../layout/Stack"

export const WhoIAm = () => {
    return (
        <Box style={{backgroundColor: "revert", minWidth: "fill-content" }}>
                <Stack>
                    <div style={{ fontSize: scale.s2 }}>
                        {whoIs.name}
                    </div>
                    <div style={{ fontSize: scale.s1, maxWidth: "60%"}}>
                        {whoIs.description}
                    </div>
                </Stack>
                
            </Box>
    )
}