// Colors
import { colors } from "../../constants/colors"

// Layout
import { Box } from "../../layout/Box"


export const InvisibleCard = ({children, id}) => {
    return (
        <Box id={`InvisibleCard--${id}`} style={{
            borderColor: colors.primaryTrim, 
            backgroundColor: colors.secondaryTrim,
            padding: "revert",
        }}>
            {children}
        </Box>
    )
}