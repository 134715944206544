// Content
import { headline } from "../constants/content";

//Scaling
import { copySize, headingSize, scale } from "../constants/cssScale";

// Layout
import { Box } from "../layout/Box";
import { Stack } from "../layout/Stack";
import { SideBar } from "../layout/SideBar";

// Colors
import { colors } from "../constants/colors";

// Images
import gravel from  '../assets/gravel.jpeg';
import mainImg from '../assets/meetingOne.jpg'
import { BasicCard } from "./subComponents/BasicCard";

export const Headline = () => {

    const imageSize = "800px"

    return (
        <BasicCard>
            <SideBar style={{"--space": "3rem"}}>
                <Box style={{ padding: 0, width: "40rem", maxHeight: "20rem", border: "none", overflow: "hidden"}} >
                   <img src={mainImg} width="800px" />
                </Box>

                <Box style={{ padding: '0', border: "none"}}>
                    <Stack style={{"--space": "2rem"}}>

                        {/* HEADING */}
                        <div style={{fontSize: headingSize, color: colors.primary}} >{ headline.title}</div>

                        {/* LIST */}
                        <Box style={{ padding: '0', border: "none"}}>
                            <Stack style={{"--space": ".25rem"}}>
                                {
                                    headline.list.map((item)=> {
                                        return(
                                            <div style={{fontSize: copySize, color: colors.primary}}>{ item }</div>
                                        )
                                    })
                                }
                            </Stack>
                        </Box>

                        {/* COPY */}
                        {
                            headline.copy.map((item)=> {
                            return (
                                <div style={{fontSize: copySize, color: colors.primary}}>{ item }</div>
                            )
                            })
                        }
                    </Stack>
                </Box>
            </SideBar>
        </BasicCard>
    )
}